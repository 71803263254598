import React from "react";
import PropTypes from "prop-types";
import "./Legend.scss";

const Legend = ({ colors, shouldAddConsumptionPrefix = true, legend }) => {
  const hasFourColors = colors.length === 4;

  return (
    <div className={`legend-container ${hasFourColors ? "four-colors" : ""}`}>
      <div className={`legend-colors`}>
        {colors.map((color, index) => (
          <div
            className={`color ${hasFourColors ? "four-colors" : ""}`}
            key={index}
          >
            <div className="step-color">{color.hexagon}</div>
            <div className="color-label">
              <span>{color.label}</span>
              {shouldAddConsumptionPrefix &&
                !color?.shouldRemoveConsumptionPrefix &&
                " consumption"}
            </div>
          </div>
        ))}
      </div>
      <div className="legend-title">{legend ? legend : "Moving average"}</div>
    </div>
  );
};

Legend.propTypes = {
  colors: PropTypes.array.isRequired
};

export default Legend;
