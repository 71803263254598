import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import AreaHeader from "../components/AreaHeader/AreaHeader";
import TimeTypeSelector from "../components/TimeTypeSelector/TimeTypeSelector";
import { allBuildingsData, buildingData, isLoading } from "../selectors/data";
import { getAllBuildingsData, getBuildingData } from "../actions/data";
import LoadingOverlay from "react-loading-overlay";
import { connect, useSelector } from "react-redux";
import Energy from "./Tabs/Energy/Energy";
import Water from "./Tabs/Water/Water";
import Wifi from "./Tabs/WiFi/Wifi";
import ObjectDetector from "./Tabs/ObjectDetector/ObjectDetector";
import MobSense from "./Tabs/MobSense/Mobsense";
import AlertsTab from "./Tabs/AlertsTab/AlertsTab";
import ActuatorsTab from "./Tabs/ActuatorsTab/ActuatorsTab";
import SettingsTab from "./Tabs/SettingsTab/SettingsTab";
import { isLITECAMPUSEnv } from "../utils/EnvUtils";

import moment from "moment";
import "moment/locale/pt-br";
import "./Dashboard.scss";
import useUserCredentialsStore from "../stores/useUserCredentialsStore";
import EquatorialEnergy from "./Tabs/EquatorialEnergy/EquatorialEnergy";
import useDateStore from "../components/Dashboard/stores/useDateStore";
import useIsMagnituteFilterOn from "../hooks/useIsMagnituteFilterOn";

const mapStateToProps = state => {
  return {
    admin: state.auth.isAdmin,
    userEmail: state.auth.userEmail
  };
};

const DefaultDashboard = ({
  admin,
  userEmail,
  area,
  tab,
  geoJson,
  areas,
  onSelectArea,
  onAlertClick,
  setSelectedArea,
  setAuthorizedGeoData,
  getAllBuildingsData,
  getBuildingData,
  setReset,
  activeCategory,
  setActiveCategory,
  timeType,
  markers,
  filteredMarkers,
  setFilteredMarkers,
  setTimeType
}) => {
  const isMagnituteFilterOn = useIsMagnituteFilterOn();

  const loggedUser = useUserCredentialsStore(state => state.loggedUser);
  const isSuperUser = useUserCredentialsStore(state => state.isSuperUser);
  const isReadOnlyUser = useUserCredentialsStore(state => state.isReadOnlyUser);

  const setLoggedUser = useUserCredentialsStore(state => state.setLoggedUser);
  const setIsSuperUser = useUserCredentialsStore(state => state.setIsSuperUser);
  const setIsReadOnlyUser = useUserCredentialsStore(
    state => state.setIsReadOnlyUser
  );

  const date = useDateStore(state => state.date);
  const setDate = useDateStore(state => state.setDate);

  moment().locale("pt-br");
  const validBuildings = geoJson.features.filter(
    feature => feature.properties.name && feature.properties.buildingId
  );
  const superUserAuthorizedBuildings = validBuildings.map(
    feature => feature.properties.buildingId
  );
  let [showAll, setShowAll] = useState(true);
  let loading = useSelector(isLoading);
  let building = useSelector(buildingData);
  let allBuildings = useSelector(allBuildingsData);

  let [mobsenseData] = useState({
    currentCount: 47,
    comparation: {
      today: {
        daily: 120.5,
        average: 141.6
      },
      month: {
        daily: 3200,
        average: 3000
      }
    },
    count: {
      today: 73,
      month: 274
    }
  });
  let [waterData] = useState({
    demand: 8,
    comparation: {
      today: {
        daily: 120.5,
        average: 141.6
      },
      month: {
        daily: 3200,
        average: 3000
      }
    },
    consumption: {
      today: 60,
      month: 2100
    }
  });

  let [showDetailed, setShowDetailed] = useState(false);

  const onTitleClick = () => {
    setShowDetailed(!showDetailed);
  };

  const onItemClick = item => {
    onSelectArea({
      name: item?.props?.name,
      buildingId: item?.key
    });
  };

  const onShowAllClick = useCallback(() => {
    setShowAll(true);
    onAlertClick("");
    onSelectArea({ buildingId: "" });
    if (area.buildingId != "") setReset(true);
  });

  const renderEnergyTab = () =>
    isMagnituteFilterOn ? (
      <EquatorialEnergy
        admin={admin}
        markers={markers}
        setFilteredMarkers={setFilteredMarkers}
      />
    ) : (
      <Energy
        area={area}
        timeType={timeType}
        date={date}
        setDate={setDate}
        demandData={building?.demand}
        chartData={building?.chart}
        allAreasData={allBuildings}
        compareData={building?.compare}
        consumptionData={building?.totalConsumption}
        statusData={building?.status}
        admin={admin}
        showAll={showAll}
        onItemClick={onItemClick}
        alertsData={building?.alerts}
        onAlertClick={onAlertClick}
        onShowAllClick={onShowAllClick}
        setTimeType={setTimeType}
        isReadOnlyUser={isReadOnlyUser}
        showDetailed={showDetailed}
        onTitleClick={onTitleClick}
        areas={allBuildings ? [...allBuildings.areas] : []}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        building={building}
        allAreasConsumption={
          allBuildings ? allBuildings.consumption : { day: 0, month: 0 }
        }
        markers={markers}
        filteredMarkers={filteredMarkers}
        setFilteredMarkers={setFilteredMarkers}
        geoJson={geoJson}
      />
    );

  const renderWaterTab = () => (
    <Water
      area={area}
      timeType={timeType}
      date={date}
      setDate={setDate}
      demandData={building?.demand}
      allAreasData={allBuildings}
      showAll={showAll}
      waterData={waterData}
      admin={admin}
      alertsData={building?.alerts}
      onAlertClick={onAlertClick}
      onItemClick={onItemClick}
      chartData={building?.chart}
      compareData={building?.compare}
      onShowAllClick={onShowAllClick}
      setTimeType={setTimeType}
      isReadOnlyUser={isReadOnlyUser}
      building={building}
    />
  );

  const renderMobsense = () => (
    <MobSense
      area={area}
      timeType={timeType}
      date={date}
      setDate={setDate}
      demandData={building?.demand}
      allAreasData={allBuildings}
      showAll={showAll}
      mobsenseData={mobsenseData}
      admin={admin}
      alertsData={building?.alerts}
      onAlertClick={onAlertClick}
      onItemClick={onItemClick}
      chartData={building?.chart}
      compareData={building?.compare}
      onShowAllClick={onShowAllClick}
      setTimeType={setTimeType}
      isReadOnlyUser={isReadOnlyUser}
    />
  );

  const renderWifiTab = () => (
    <Wifi
      showAll={showAll}
      setShowAll={setShowAll}
      selectedArea={area}
      setSelectedArea={setSelectedArea}
    />
  );

  const renderObjectDetector = () => (
    <ObjectDetector
      currentTab={tab}
      onSelectArea={onSelectArea}
      type="video"
      url="https://www.youtube.com/watch?v=384y2q9k1Zo"
    ></ObjectDetector>
  );

  const renderAlertsTab = () => (
    <AlertsTab
      area={area}
      allAreasData={allBuildings}
      showAll={showAll}
      admin={admin}
      alertsData={building?.alerts}
      onAlertClick={onAlertClick}
      onShowAllClick={onShowAllClick}
      isReadOnlyUser={isReadOnlyUser}
    />
  );

  const renderActuatorsTab = () => (
    <ActuatorsTab
      area={area}
      allAreasData={allBuildings}
      showAll={showAll}
      admin={admin}
      actuatorsData={building?.actuators}
      onActuatorClick={onAlertClick}
      onShowAllClick={onShowAllClick}
      isReadOnlyUser={isReadOnlyUser}
    />
  );

  const renderSettingsTab = () => (
    <SettingsTab
      areas={areas}
      admin={admin}
      users={allBuildings.users}
      areasData={allBuildings.areas}
    />
  );

  useEffect(() => {
    if (!isLITECAMPUSEnv && loggedUser) {
      const authorizedBuildings = isSuperUser
        ? superUserAuthorizedBuildings
        : loggedUser.authorizedBuildings
        ? loggedUser.authorizedBuildings
        : [];

      if (areas !== authorizedBuildings) {
        const authorizedFeatures = geoJson.features.filter(feature =>
          authorizedBuildings.includes(feature.properties.buildingId)
        );

        const authorizedGeoData = {
          type: geoJson.type,
          features: authorizedFeatures
        };

        setAuthorizedGeoData(authorizedGeoData);
        if (JSON.stringify(geoJson) !== JSON.stringify(authorizedGeoData))
          getAllBuildingsData(date, authorizedGeoData);
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    if (allBuildings?.users?.length > 0 && userEmail) {
      let userAuth = undefined;
      allBuildings.users.forEach(user => {
        if (user.email === userEmail) {
          userAuth = user;
        }
      });

      if (userAuth && (!loggedUser || loggedUser !== userAuth)) {
        const hasCampusManagerRole = userAuth.roles.includes(
          "LITECAMPUS_MANAGER"
        );
        const hasReadOnlyRole = userAuth.roles.includes("RNP_READ_ONLY_USER");

        if (userAuth) setLoggedUser(userAuth);
        setIsSuperUser(hasCampusManagerRole);
        setIsReadOnlyUser(
          hasReadOnlyRole || (isLITECAMPUSEnv && !hasCampusManagerRole)
        );
      }
    }
  }, [allBuildings]);

  useEffect(() => {
    if (isLITECAMPUSEnv && userEmail) {
      let id = JSON.parse(localStorage.getItem("user")).id;
      let filteredBuildings = geoJson.features.filter(
        feature => feature.properties.userId === id
      );
      setAuthorizedGeoData({
        type: geoJson.type,
        features: filteredBuildings
      });
    }
  }, [userEmail]);

  useEffect(() => {
    const updateData = () => {
      getAllBuildingsData(date, geoJson);
    };

    if (
      tab === "energy" ||
      tab === "water" ||
      tab === "alerts" ||
      tab === "actuators" ||
      tab === "settings"
    ) {
      updateData();
      const intervalId = setInterval(updateData, 15 * 1000 * 60);

      return () => clearInterval(intervalId);
    }
  }, [date, tab]);

  useEffect(() => {
    if (!showAll && !area.ignoreMeasurements && area.buildingId) {
      getBuildingData(area.buildingId, allBuildings.areas, date);
    }

    if (allBuildings?.areas?.length == 1) {
      setSelectedArea(allBuildings?.areas[0]);
    }
  }, [allBuildings, area, areas, showAll]);

  useEffect(() => {
    if (!showDetailed) {
      setFilteredMarkers(markers);
    }
  }, [showDetailed]);

  useEffect(() => {
    if (area.buildingId) {
      setShowAll(false);
    }
  }, [area]);

  useEffect(() => {
    if (area.ignoreMeasurements) {
      onShowAllClick();
    }
  }, [area.ignoreMeasurements, onShowAllClick, tab]);

  const renderTab = tab => {
    localStorage.setItem("CurrentTab", tab);
    switch (tab) {
      case "energy":
        return renderEnergyTab();
      case "water":
        return renderWaterTab();
      case "wifi":
        return renderWifiTab();
      case "objectDetector":
        return renderObjectDetector();
      case "mobsense":
        return renderMobsense();
      case "alerts":
        return renderAlertsTab();
      case "actuators":
        return renderActuatorsTab();
      case "settings":
        return renderSettingsTab();
    }
  };

  const showHeader = tab => {
    return (
      tab !== "energy" &&
      tab !== "water" &&
      tab !== "mobsense" &&
      tab !== "alerts" &&
      tab !== "actuators" &&
      tab !== "settings"
    );
  };

  LoadingOverlay.propTypes = undefined;

  return (
    <div className="default-dashboard">
      <LoadingOverlay
        className="loading-overlay"
        spinner={true}
        active={loading}
      >
        <div className="area-total">
          {showHeader(tab) && (
            <Row className="header">
              <Col className="header-column">
                <AreaHeader
                  areaName={area.name}
                  areaUpperName={process.env.REACT_APP_CAMPUS}
                  onUpperNameClick={onShowAllClick}
                  showAll={showAll}
                />
                {(tab === "energy" || tab === "water") && (
                  <TimeTypeSelector
                    timeType={timeType}
                    onTimeTypeChange={setTimeType}
                  />
                )}
              </Col>
            </Row>
          )}

          {renderTab(tab)}
        </div>
      </LoadingOverlay>
    </div>
  );
};

DefaultDashboard.propTypes = {
  admin: PropTypes.bool,
  tab: PropTypes.string,
  timeType: PropTypes.string,
  setTimeType: PropTypes.func,
  area: PropTypes.object,
  geoJson: PropTypes.object,
  areas: PropTypes.array,
  onSelectArea: PropTypes.func,
  onAlertClick: PropTypes.func,
  setSelectedArea: PropTypes.func,
  setAuthorizedGeoData: PropTypes.func
};

export default connect(mapStateToProps, {
  getAllBuildingsData,
  getBuildingData
})(DefaultDashboard);
