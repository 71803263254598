import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signOut } from "../../actions/auth";
import ChangeTabButton from "./ChangeTabButton";
import useUserCredentialsStore from "../../stores/useUserCredentialsStore";
import ConsumptionOrMagnitudeSelector from "./ConsumptionOrMagnitudeSelector/ConsumptionOrMagnitudeSelector";

const mapStateToProps = state => {
  return {
    admin: state.auth.isAdmin,
    isSignedIn: state.auth.isSignedIn
  };
};

const SideMenuHeader = ({
  area,
  admin,
  isSignedIn,
  tabs,
  currentTab,
  onTabChange
}) => {
  const isSuperUser = useUserCredentialsStore(state => state.isSuperUser);
  const isEnergy = currentTab === "energy";

  return (
    <div className="header-container">
      <div
        className={"change-type-container " + currentTab}
        style={{ backgroundColor: tabs[currentTab].color }}
      >
        <div className="header-title">{tabs[currentTab].title}</div>
        {isEnergy && <ConsumptionOrMagnitudeSelector />}
        <div className="header-tabs">
          {Object.keys(tabs).map(tab => {
            if (
              // tab !== "water" &&
              (admin && isSuperUser) ||
              (admin && isSignedIn && tab !== "settings") ||
              (tab !== "actuators" && tab !== "alerts" && tab !== "settings")
            )
              return (
                <ChangeTabButton
                  area={area}
                  key={tab}
                  tab={tab}
                  currentTab={currentTab}
                  onTabChange={onTabChange}
                ></ChangeTabButton>
              );
          })}
        </div>
      </div>
    </div>
  );
};

SideMenuHeader.propTypes = {
  area: PropTypes.object,
  admin: PropTypes.bool,
  tabs: PropTypes.object,
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func,
  user: PropTypes.object,
  signOut: PropTypes.func
};

export default connect(mapStateToProps, { signOut })(SideMenuHeader);
