import React, { useEffect, useState } from "react";
import AlertList from "../../../components/AlertList/AlertList";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import alertTriggersEnum from "../../../components/Enum/alert-triggers.json";

import "../../Dashboard.scss";

const AlertsTab = ({
  area,
  admin,
  showAll,
  alertsData,
  onAlertClick,
  allAreasData,
  onShowAllClick,
  isReadOnlyUser
}) => {
  const [triggers, setTriggers] = useState(
    showAll ? allAreasData?.alerts : alertsData
  );

  useEffect(() => {
    let aux = [{ label: "Todos", field: "all" }];

    for (let i = 0; i < allAreasData?.alerts.length; i++) {
      let t = { label: "Desconhecido", field: undefined };

      if (allAreasData?.alerts[i].trigger) {
        t = {
          label: alertTriggersEnum[allAreasData.alerts[i].trigger.type]?.label,
          field: allAreasData?.alerts[i]?.trigger?.type
        };
      }

      if (
        !aux.some(elem => {
          return JSON.stringify(t) === JSON.stringify(elem);
        })
      ) {
        aux.push(t);
      }
    }

    setTriggers(aux);
  }, [showAll, alertsData, allAreasData]);

  return (
    <>
      <section className="header">
        <AreaHeader
          areaName={area.name}
          areaUpperName={process.env.REACT_APP_CAMPUS}
          onUpperNameClick={onShowAllClick}
          showAll={showAll}
          typeMeter="medição"
          showBackButton={allAreasData?.areas?.length > 1}
        />
      </section>
      {admin && (
        <section className="list-row">
          <AlertList
            alerts={showAll ? allAreasData?.alerts : alertsData}
            onAlertClick={onAlertClick}
            buildingId={area.buildingId}
            buildings={allAreasData?.areas}
            isReadOnlyUser={isReadOnlyUser}
            triggers={triggers}
          />
        </section>
      )}
    </>
  );
};

export default AlertsTab;
