import { SIGN_IN, SIGN_OUT } from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: false,
  isAdmin: false,
  user: null,
  userEmail: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        isAdmin: action.payload.isAdmin,
        user: action.payload.user,
        userEmail: action.payload.email
      };
    case SIGN_OUT:
      return { ...state, isSignedIn: false, isAdmin: false, user: null };
    default:
      return state;
  }
};
