import React from "react";
import ActuatorList from "../../../components/ActuatorList/ActuatorList";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";

import "../../Dashboard.scss";

const ActuatorsTab = ({
  area,
  admin,
  showAll,
  actuatorsData,
  onActuatorClick,
  allAreasData,
  onShowAllClick,
  isReadOnlyUser
}) => {
  return (
    <>
      <section className="header">
        <div className="header-column">
          <AreaHeader
            areaName={area.name}
            areaUpperName={process.env.REACT_APP_CAMPUS}
            onUpperNameClick={onShowAllClick}
            showAll={showAll}
            typeMeter="atuação"
            fontType="energy"
            showBackButton={allAreasData?.areas?.length > 1}
          />
        </div>
      </section>
      {admin && (
        <section className="list-row">
          <div>
            <ActuatorList
              area={area}
              actuators={
                showAll
                  ? allAreasData
                    ? allAreasData.actuators
                    : actuatorsData
                  : actuatorsData
              }
              onActuatorClick={onActuatorClick}
              buildings={allAreasData ? allAreasData.areas : []}
              isReadOnlyUser={isReadOnlyUser}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default ActuatorsTab;
