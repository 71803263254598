import React, { useState, useEffect } from "react";
import Label from "./Label";
import PropTypes from "prop-types";

import "./Bars.scss";
import HexBar from "./HexBar";

const Bars = ({ daily, average, type }) => {
  let [firstBar, setFirstBar] = useState(0);
  let [secondBar, setSecondBar] = useState(0);

  useEffect(() => {
    if (daily > average) {
      setFirstBar(120);
      setSecondBar((120 * average) / daily);
    } else if (daily === 0 && average === 0) {
      setFirstBar(0);
      setSecondBar(0);
    } else {
      setFirstBar((120 * daily) / average);
      setSecondBar(120);
    }
  }, [daily, average]);

  return (
    <div className="chart">
      <Label className="right" title="Current" value={daily} type={type} />
      <div className="bars">
        <HexBar height={firstBar} type={type} />
        <HexBar height={secondBar} type={type} />
      </div>
      <Label title="Average" value={average} type={type} />
    </div>
  );
};

Bars.propTypes = {
  daily: PropTypes.number,
  average: PropTypes.number,
  type: PropTypes.string
};

export default Bars;
