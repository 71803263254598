import React from "react";
import PropTypes from "prop-types";
import Hexagon from "../Hexagon/Hexagon";
import Label from "../Label/Label";
import moment from "moment";
import { formatConsumption, getConsumptionUnit } from "../../utils/Formatter";
import "./ConsumptionWidget.scss";

const ConsumptionWidget = ({ consumption, type, timeType, date }) => {
  // const getUnit = () => {
  //   return type === "energy" ? getConsumptionUnit(consumption) : "m³";
  // };

  const getTitle = () => {
    if (type === "mobsense") {
      return "Nº de Pessoas - " + (timeType === "today" ? "Hoje" : "Mês");
    } else {
      return timeType === "today"
        ? "Today's Consumption"
        : "Monthly Consumption";
    }
  };

  const getTime = () => {
    let time = "00h - ";
    if (timeType === "today") {
      time +=
        moment(date).format("L") ===
        moment()
          .locale("en")
          .format("L")
          ? moment().hour() +
            "h" +
            moment()
              .locale("en")
              .format("mm")
          : "23h59";
    } else {
      time = moment(date)
        .locale("en")
        .format("MMMM");
    }
    return time;
  };

  return (
    <div className="consumption-widget-container">
      <Hexagon
        outlined
        consumption={formatConsumption(consumption)}
        unity={type === "water" ? "m³" : getConsumptionUnit(consumption)}
        type={type}
      />
      <Label title={getTitle()} type={type} subtitle={getTime()} />
    </div>
  );
};

ConsumptionWidget.propTypes = {
  unity: PropTypes.string,
  consumption: PropTypes.number,
  timeType: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.instanceOf(Date)
};

export default ConsumptionWidget;
