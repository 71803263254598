import React from "react";
import { ListGroup, Row } from "react-bootstrap";
import "./AreaList.scss";
import useGetMeasurementUtils from "../../Dashboard/Tabs/EquatorialEnergy/hooks/useGetMeasurementUtils";

const EquatorialAreaList = ({ children, type, onItemClick, header }) => {
  const { hasNeutralValue, hasTotalValue } = useGetMeasurementUtils();

  const TableTitle = () => (
    <ListGroup.Item style={{ cursor: "default", position: "sticky", top: "0" }}>
      <Row className="equatorial-list-header">{header}</Row>
      <div className="thead table-head">
        <div className="table-head-name title">
          <div className="a"></div>
          <div className="bigger-screen">Measurement Point</div>
          <div className="smaller-screen">Point</div>
        </div>
        {hasNeutralValue && (
          <div className="list-head-value equatorial-title">
            <b>Neutro</b>
          </div>
        )}
        <div className="list-head-value equatorial-title">
          <div className="bigger-screen">
            Phase <b>A</b>
          </div>
          <div className="smaller-screen">
            <b>A</b>
          </div>
        </div>
        <div className="list-head-value equatorial-title">
          <div className="bigger-screen">
            Phase <b>B</b>
          </div>
          <div className="smaller-screen">
            <b>B</b>
          </div>
        </div>
        <div className="list-head-value equatorial-title">
          <div className="bigger-screen">
            Phase <b>C</b>
          </div>
          <div className="smaller-screen">
            <b>C</b>
          </div>
        </div>
        {hasTotalValue && (
          <div className="list-head-value equatorial-title">
            <b>Total</b>
          </div>
        )}
      </div>
    </ListGroup.Item>
  );

  return (
    <div className="area-list-container">
      <ListGroup className={type}>
        <TableTitle />
        {children &&
          children.map((child, index) => (
            <ListGroup.Item
              key={index}
              className={`${type} area-list-item`}
              onClick={() =>
                onItemClick({
                  name: child.props.name,
                  buildingId: child.key
                })
              }
            >
              {child}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
};

export default EquatorialAreaList;
