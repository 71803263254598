import React, { useState, useEffect } from "react";
import UserList from "../../../components/UserList/UserList";
import NewUserModal from "../../../components/NewUserModal/NewUserModal";
import "./SettingsTab.scss";
import Areas from "./Areas";
import Categories from "./Categories";

const SettingsTab = ({ admin, users, areas, areasData }) => {
  const [userModalShow, setUserModalShow] = useState(false);
  const [userList, setUserList] = useState(users);
  const [activeTab, setActiveTab] = useState(0);
  const buildings = areas.map(area => area.properties);
  const [areasCategory, setAreasCategory] = useState([]);

  useEffect(() => {
    let a = [];

    areasData.forEach(building => {
      let area = {};
      area.name = building.name;
      area.id = building.buildingId;
      area.category = building.category;
      a.push(area);
    });

    setAreasCategory(a);
  }, [areasData]);

  useEffect(() => {
    setUserList(users);
  }, [users]);

  return (
    <div className="settings-page">
      <section className="settings-tabs">
        <div
          className={"cat-btn " + `${activeTab === 0 ? "on" : "off"}`}
          onClick={() => setActiveTab(0)}
        >
          Usuários
        </div>
        <div
          className={"cat-btn " + `${activeTab === 1 ? "on" : "off"}`}
          onClick={() => setActiveTab(1)}
        >
          Áreas
        </div>
        <div
          className={"cat-btn " + `${activeTab === 2 ? "on" : "off"}`}
          onClick={() => setActiveTab(2)}
        >
          Categorias
        </div>
      </section>
      <section className="settings-page-content">
        {activeTab === 0 ? (
          admin && (
            <>
              <NewUserModal
                show={userModalShow}
                setModalShow={setUserModalShow}
                onHide={() => setUserModalShow(false)}
                setUserList={setUserList}
              />
              <section className="add-user-row">
                <div className="add-user-col">
                  <div
                    className="add-user-btn"
                    onClick={() => setUserModalShow(!userModalShow)}
                  >
                    Adicionar Usuário
                  </div>
                </div>
              </section>
              <section className="list-row">
                <UserList users={userList} buildings={buildings} />
              </section>
            </>
          )
        ) : activeTab === 1 ? (
          <Areas buildings={areasCategory} />
        ) : (
          <Categories tab={activeTab} />
        )}
      </section>
    </div>
  );
};

export default SettingsTab;
