import React from "react";
import "./Legend.scss";

const LegendSwitch = ({ title, onClick }) => {
  return (
    <div className="legend-switch-container" onClick={onClick}>
      <div className="title">
        {title === "Consumo" ? "Moving Average" : "Consumption"}
      </div>
    </div>
  );
};

export default LegendSwitch;
