import { AlertsService } from "./Service";
import { getRealBuildingId } from "./Simulation";

const getCurrentTabIsAlerts = () => {
  const currentTab = localStorage.getItem("CurrentTab");
  return currentTab === "alerts";
};

export const getAlertLogs = async buildingId => {
  if (!getCurrentTabIsAlerts()) return [];

  let response = await AlertsService.get(
    "withoutauth/building/" +
      getRealBuildingId(buildingId) +
      "/sensors/alerts/logs"
  )
    .then(res => res.data)
    .catch(err => console.error(err));

  let data = response.data.map(alert => {
    alert.buildingId = buildingId;
    return alert;
  });
  return data ? data : [];
};

export const registerAlert = async (buildingId, alertDescriptor) => {
  try {
    await AlertsService.post(
      "withoutauth/building/" + getRealBuildingId(buildingId) + "/alert",
      alertDescriptor,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const encodeAlertUpdateData = (read, archived, mitigated) => {
  return (
    "read=" +
    (read ? "true" : "false") +
    "&" +
    "archived=" +
    (archived ? "true" : "false") +
    "&" +
    "mitigated=" +
    (mitigated ? "true" : "false")
  );
};

export const updateAlert = async (alertId, read, archived, mitigated) => {
  let formData = encodeAlertUpdateData(read, archived, mitigated);
  try {
    let response = await AlertsService.put(
      "withoutauth/alert/logs/" + alertId,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    )
      .then(res => res.data)
      .catch(err => console.error(err));
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteAlert = async alertId => {
  try {
    await AlertsService.delete("/withoutauth/alert/logs/" + alertId)
      .then(res => res.data)
      .catch(err => console.error(err));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
