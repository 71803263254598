import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import {
  GoogleMap,
  Marker,
  Polygon,
  LoadScript,
  Circle,
  InfoWindow
} from "@react-google-maps/api";
import {
  available,
  wifiStyle,
  polygonAlertStyle,
  unavailableStyle,
  mobsenseStyle,
  tweakedAvailable,
  actuatorsStyle
} from "./MapStyles";
import centerOfMass from "@turf/center-of-mass";
import * as turf from "@turf/helpers";
import { connect } from "react-redux";
import alertIcon from "../../assets/img/alert.svg";
import wifiIcon from "../../assets/img/wifi.svg";
import sensorIcon from "../../assets/img/sensor.svg";
import transparentIcon from "../../assets/img/transparent.png";
import pinIconInst from "../../assets/img/pin.png";
import pinIconConsumptionNormal from "../../assets/img/litecampus-pin.svg";
import pinIconConsumptionOffline from "../../assets/img/litecampus-pin-grey.svg";
import pinIconConsumptionBelow from "../../assets/img/litecampus-pin-blue.svg";
import pinIconConsumptionAbove from "../../assets/img/litecampus-pin-red.svg";
import MarkerSelector from "./MarkerSelector";
import SimulationDisclaimer from "./SimulationDisclaimer";
import GradientRule from "./GradientRule";
import { getGeometries, getCoordinates } from "../../utils/GeoUtils";
import { isNotACampus } from "../../utils/EnvUtils";
import "./Map.scss";
import Legend from "./Legend";
import Hexagon from "./Hexagon";
import LegendSwitch from "./LegendSwitch";
import useUserCredentialsStore from "../../stores/useUserCredentialsStore";
import { useTimeoutFn } from "react-use";
import useEquatorialTableDataStore from "../../Dashboard/Tabs/EquatorialEnergy/stores/useEquatorialTableDataStore";
import useFormatEquatorialValues from "../../Dashboard/Tabs/EquatorialEnergy/hooks/useFormatEquatorialValues";

const mapStateToProps = state => {
  return {
    buildings: state.wifi.buildings,
    sensors: state.mobsense ? state.mobsense.sensors : []
  };
};

const mapStyle = [
  {
    elementType: "labels",
    featureType: "poi",
    stylers: [{ visibility: "off" }]
  },
  {
    elementType: "labels",
    featureType: "landscape.man_made",
    stylers: [{ visibility: "off" }]
  }
];

const gradientColors = [
  "#bfd3e6",
  "#9ebcda",
  "#8c96c6",
  "#8c6bb1",
  "#88419d",
  "#810f7c",
  "#4d004b"
];

const steps = [0.14, 0.28, 0.42, 0.57, 0.71, 0.85, 1];

const getStyle = (feature, style, buildings, highlights, selectedMarker) => {
  let polygonStyle;
  const isPolygonAvailable = isAvailable(feature, style, buildings);
  const shouldTweakPolygon =
    selectedMarker !== "alerts" &&
    highlights &&
    highlights[feature.properties.name];

  switch (style) {
    case "actuators":
      polygonStyle = actuatorsStyle;
      break;
    case "wifi":
      polygonStyle = isPolygonAvailable ? available : wifiStyle;
      break;
    case "energy":
      polygonStyle = isPolygonAvailable
        ? shouldTweakPolygon
          ? tweakedAvailable(highlights[feature.properties.name])
          : available
        : unavailableStyle;
      break;
    default:
      polygonStyle = isPolygonAvailable ? available : unavailableStyle;
  }

  if (feature.properties.isArea) {
    polygonStyle.fillOpacity = 0.4;
    polygonStyle.strokeColor = "#FFFFFF";
    polygonStyle.strokeWeight = 2;
  }

  return polygonStyle;
};

const isAvailable = (feature, tab, buildings) => {
  switch (tab) {
    case "energy":
    case "alerts":
    case "actuators":
    case "water":
      if (feature.properties.buildingId) return true;
      break;
    case "wifi":
      if (buildings.some(e => e.name === feature.properties.name)) return true;
      break;
    case "objectDetector":
      return feature.properties.name === "Guarita";
    default:
      return false;
  }
};

const renderAlertsPoints = (data, geoJson) => {
  if (!geoJson || !data) return;
  let buildingIds = data.alerts.map(alert => alert.buildingId);
  buildingIds = Array.from(new Set(buildingIds));
  let alerts = geoJson.features.filter(e =>
    buildingIds.includes(e.properties.buildingId)
  );
  return alerts.map(feature => {
    let center = centerOfMass(turf.polygon(getCoordinates(feature)));
    return (
      <Marker
        key={feature.properties.buildingId}
        position={{
          lat: center.geometry.coordinates[1],
          lng: center.geometry.coordinates[0]
        }}
        icon={{
          url: alertIcon,
          scaledSize: new window.google.maps.Size(22, 22)
        }}
      />
    );
  });
};

const ConsumptionMarker = ({
  feature,
  center,
  onClick,
  isMagnituteFilterOn
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasAlerts = feature.properties.equatorialStatus === "has-alerts";
  const totalActivePower = feature.properties.totalActivePower;

  return (
    <Marker
      key={feature.properties.buildingId}
      position={{
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0]
      }}
      icon={{
        url: transparentIcon,
        scaledSize: new window.google.maps.Size(22, 22),
        anchor: new window.google.maps.Point(7, 7)
      }}
      onClick={() => {
        if (isAvailable(feature, "energy")) onClick(feature.properties);
      }}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      {isOpen && (
        <InfoWindow
          onCloseClick={() => setIsOpen(false)}
          position={{
            lat: center.geometry.coordinates[1],
            lng: center.geometry.coordinates[0]
          }}
        >
          <div style={{ fontSize: "14px" }}>
            <span>
              <strong>{feature.properties.name}</strong>
              <br />
              <br />
            </span>
            {isMagnituteFilterOn && totalActivePower && (
              <span>
                <strong>Potência Ativa Total:</strong> {totalActivePower}
                <br />
              </span>
            )}
            <span>
              <strong>Consumo:</strong>{" "}
              {formatMarkerLabel(feature.properties.totalConsumption)}
              <br />
            </span>
            <span>
              <strong>Percentual de Consumo:</strong>{" "}
              {formatMarkerLabel(
                feature.properties.totalConsumptionPercent,
                true
              )}
              <br />
            </span>
            {isMagnituteFilterOn && (
              <span>
                <br />
                {`${hasAlerts ? "Com" : "Sem"} alertas nas últimas três horas`}
                <br />
              </span>
            )}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const formatMarkerLabel = (value, percent = false) => {
  let label = "";
  if (value || value === 0) {
    label = value.toString().split(".")[0];
    if (percent) {
      let decimal = value.toString().split(".")[1];
      if (label === "0" && decimal) {
        label += ".";
        label += decimal.split("")[0];
      }
      label += "%";
    } else label += " kWh";
  }
  return label;
};

const ActuatorMarker = ({ feature, center, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Marker
      key={feature.properties.buildingId}
      position={{
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0]
      }}
      icon={{
        url: transparentIcon,
        scaledSize: new window.google.maps.Size(22, 22),
        anchor: new window.google.maps.Point(7, 7)
      }}
      onClick={() => {
        onClick(feature.properties);
      }}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      {isOpen && (
        <InfoWindow
          onCloseClick={() => setIsOpen(false)}
          position={{
            lat: center.geometry.coordinates[1],
            lng: center.geometry.coordinates[0]
          }}
        >
          <div style={{ fontSize: "14px" }}>
            <span>
              <strong>{feature.properties.name}</strong>
              <br />
              <br />
            </span>
            <span>
              <strong>Total de atuadores:</strong> {feature.totalActuators}
              <br />
            </span>
            <span>
              <strong>Atuadores ligados:</strong> {feature.onActuators}
              <br />
            </span>
            <span>
              <strong>Atuadores desligados:</strong> {feature.offActuators}
              <br />
            </span>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const renderActuatorsPoints = (data, geoJson, onClick) => {
  if (!geoJson || !data) return;

  const actuatorsBuildings = data.actuators.map(
    actuator => actuator.building_id.$oid
  );
  let features = geoJson.features.filter(feature =>
    actuatorsBuildings.includes(feature.properties.buildingId)
  );

  features.forEach(feature => {
    const featureActuators = data.actuators.filter(
      actuator => actuator.building_id.$oid === feature.properties.buildingId
    );
    feature.totalActuators = featureActuators.length;
    feature.onActuators = featureActuators.filter(
      actuator => actuator.power
    ).length;
    feature.offActuators = feature.totalActuators - feature.onActuators;
  });

  return features.map((feature, index) => {
    let center = centerOfMass(turf.polygon(getCoordinates(feature)));
    return (
      <ActuatorMarker
        key={index}
        feature={feature}
        center={center}
        onClick={onClick}
      ></ActuatorMarker>
    );
  });
};

const getGradientRulePoints = (data, features, timeType) => {
  let highestConsumption = getHighestConsumption(data, features, timeType);
  let points = [];
  gradientColors.forEach((color, index) => {
    points.push({
      color: color,
      value: formatMarkerLabel(steps[index] * highestConsumption)
    });
  });
  return points.reverse();
};

const getHighestConsumption = (data, features, timeType) => {
  features = features.filter(
    x => !x.properties.shouldIgnoreValueInGradientRule
  );
  features = features.map(x => x.properties.buildingId);
  data = data.filter(x => features.includes(x.buildingId));

  let highestConsumption;

  if (data.length > 0) {
    data.forEach(building => {
      let totalConsumption =
        timeType === "today"
          ? building.totalConsumption.day
          : building.totalConsumption.month;
      if (!highestConsumption || totalConsumption > highestConsumption) {
        highestConsumption = totalConsumption;
      }
    });
  }

  return highestConsumption;
};

const getHighlights = (data, features, timeType) => {
  let highestConsumption = getHighestConsumption(data, features, timeType);
  let coloredData = {};

  data.forEach(building => {
    let factor =
      timeType === "today"
        ? building.totalConsumption.day / highestConsumption
        : building.totalConsumption.month / highestConsumption;
    coloredData[building.name] = interpolateColor(factor);
  });

  return coloredData;
};

const interpolateColor = factor => {
  switch (true) {
    case factor < steps[0]:
      return gradientColors[0];
    case factor < steps[1]:
      return gradientColors[1];
    case factor < steps[2]:
      return gradientColors[2];
    case factor < steps[3]:
      return gradientColors[3];
    case factor < steps[4]:
      return gradientColors[4];
    case factor < steps[5]:
      return gradientColors[5];
    case factor <= steps[6]:
      return gradientColors[6];
    default:
      return gradientColors[6];
  }
};

const renderPolygons = (
  geoJson,
  onClick,
  style,
  buildings,
  data,
  selectedMarker,
  timeType
) => {
  if (!geoJson || !data) return;

  let features = geoJson.features;

  let highlights = getHighlights(data.areas, features, timeType);

  if (style === "actuators") {
    const actuatorsBuildings = data.actuators.map(
      actuator => actuator.building_id.$oid
    );
    features = features.filter(feature =>
      actuatorsBuildings.includes(feature.properties.buildingId)
    );
  }

  return features.map(feature => {
    const geometries = getGeometries(feature);
    return geometries.map((geometry, index) => (
      <Polygon
        key={index}
        path={geometry.coordinates[0].map(point => ({
          lat: point[1],
          lng: point[0]
        }))}
        options={getStyle(
          feature,
          style,
          buildings,
          highlights,
          selectedMarker
        )}
        onClick={() => {
          if (style === "wifi" || isAvailable(feature, style))
            onClick(feature.properties);
        }}
      ></Polygon>
    ));
  });
};

const renderLegend = () => {
  let colors = [
    {
      label: "above",
      hexagon: <Hexagon stroke="#F15B29" />
    },
    {
      label: "average",
      hexagon: <Hexagon stroke="#FDC200" />
    },
    {
      label: "below",
      hexagon: <Hexagon stroke="#0B5E96" />
    },
    {
      label: "offline",
      shouldRemoveConsumptionPrefix: true,
      hexagon: <Hexagon stroke="#D2D4D2" />
    }
  ];

  return <Legend colors={colors}></Legend>;
};

const renderEquatorialLegend = () => {
  let colors = [
    {
      label: "Sem Alertas",
      hexagon: (
        <Hexagon stroke="#0B5E96" title="Sem alertas nas últimas três horas" />
      )
    },
    {
      label: "Com Alertas",
      hexagon: (
        <Hexagon stroke="#FDC200" title="Com alertas nas últimas três horas" />
      )
    },
    {
      label: "Falta de Energia",
      hexagon: <Hexagon stroke="#F15B29" />
    },
    {
      label: "Offline",
      hexagon: <Hexagon stroke="#D2D4D2" />
    }
  ];

  return (
    <Legend
      colors={colors}
      shouldAddConsumptionPrefix={false}
      legend={"Status"}
    ></Legend>
  );
};

const renderGradientRule = (data, geoJson, timeType) => {
  let points = getGradientRulePoints(data, geoJson.features, timeType);

  return <GradientRule points={points}></GradientRule>;
};

const renderWifiPoints = (buildings, geoJson) => {
  if (!geoJson || !buildings) return;

  let points = buildings.map(building => building.name);
  let wifi = geoJson.features.filter(e => points.includes(e.properties.name));
  return wifi.map(feature => {
    let center = centerOfMass(turf.polygon(getCoordinates(feature)));
    return (
      <Marker
        key={feature.properties.buildingId}
        position={{
          lat: center.geometry.coordinates[1],
          lng: center.geometry.coordinates[0]
        }}
        icon={{
          url: wifiIcon,
          scaledSize: new window.google.maps.Size(20, 20)
        }}
      />
    );
  });
};

const InfoMarker = ({ sensor, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div {...props}>
      <Marker
        position={sensor.coords}
        icon={{
          url: sensorIcon,
          scaledSize: new window.google.maps.Size(25, 25)
        }}
        onClick={() => setIsOpen(true)}
      >
        {isOpen && (
          <InfoWindow
            onCloseClick={() => setIsOpen(false)}
            position={sensor.coords}
          >
            <div style={{ fontSize: "14px" }}>
              <span>
                <strong>Sensor:</strong> {sensor.id}
                <br />
              </span>
              <span>
                <strong>Contagem:</strong> {sensor.count} Pessoas
                <br />
              </span>
              <span>
                <strong>Localização:</strong> {sensor.coords.lat},{" "}
                {sensor.coords.lng}
              </span>
            </div>
          </InfoWindow>
        )}
      </Marker>
      <Circle
        options={mobsenseStyle}
        center={sensor.coords}
        radius={5 + sensor.count}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      />
    </div>
  );
};

const renderPeoplePoints = sensors => {
  return sensors.map(sensor => {
    return <InfoMarker key={sensor.id} sensor={sensor}></InfoMarker>;
  });
};

const renderSelectedAlertArea = (selectedAlertArea, onClick) => {
  const geometries = getGeometries(selectedAlertArea);
  return geometries.map((geometry, index) => (
    <Polygon
      key={"selectedArea-" + index}
      path={geometry.coordinates[0].map(point => ({
        lat: point[1],
        lng: point[0]
      }))}
      options={polygonAlertStyle}
      onClick={() => {
        onClick(selectedAlertArea.properties);
      }}
    ></Polygon>
  ));
};

const renderSelectMarker = (marker, onMarkerChange) => {
  return <MarkerSelector marker={marker} onMarkerChange={onMarkerChange} />;
};

const Map = ({
  center,
  geoJson,
  onPolygonClick,
  selectedAlertArea,
  style,
  sensors,
  buildings,
  allAreasData,
  zoom,
  reset,
  setReset,
  area,
  timeType,
  filteredMarkersAllowed,
  isMagnituteFilterOn
}) => {
  const loggedUser = useUserCredentialsStore(state => state.loggedUser);

  const equatorialTableData = useEquatorialTableDataStore(
    state => state.equatorialTableData
  );

  const [currentGeoJson, setCurrentGeoJson] = useState();
  const [currentCenter, setCurrentCenter] = useState(center);
  const [mapLoadded, setMapLoadded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState("consumption");
  const [zoomLevel, setZoomLevel] = useState(zoom);
  const [map, setMap] = useState();
  const isDEMOEnv = process.env.REACT_APP_CAMPUS === "DEMO";
  const isLITECAMPUSEnv = process.env.REACT_APP_CAMPUS === "LITECAMPUS";
  const isUFCGEnv = process.env.REACT_APP_CAMPUS === "UFCG";
  const [legend, setLegend] = useState(isUFCGEnv ? "Consumo" : "Média móvel");
  const [pins, setPins] = useState({});

  const getPinStatusIcon = status => {
    return {
      undefined: pinIconConsumptionOffline,
      offline: pinIconConsumptionOffline,
      invalid: pinIconConsumptionNormal,
      normal: pinIconConsumptionNormal,
      above: pinIconConsumptionAbove,
      below: pinIconConsumptionBelow
    }[status];
  };

  const getEquatorialPinStatusIcon = (status, online) => {
    const pin = {
      "out-of-service": pinIconConsumptionAbove,
      "has-alerts": pinIconConsumptionNormal,
      normal: pinIconConsumptionBelow
    }[status];

    return online ? pin : pinIconConsumptionOffline;
  };

  const getStatusFromEquatorialTableData = buildingId => {
    const area = equatorialTableData.find(
      data => data.buildingId === buildingId
    );

    return getEquatorialPinStatusIcon(area?.status || "normal", area?.online);
  };

  const getRawStatusFromEquatorialTableData = buildingId => {
    const area = equatorialTableData.find(
      data => data.buildingId === buildingId
    );

    return area?.status;
  };

  const { formatValue } = useFormatEquatorialValues();

  const getTotalActivePowerFromEquatorialTableData = buildingId => {
    const area = equatorialTableData.find(
      area => area.buildingId === buildingId
    );

    return `${formatValue(area?.active_power?.value)} kW`;
  };

  const renderConsumptionPoints = (data, geoJson, onClick, timeType) => {
    if (!geoJson || !data) return;

    let areaData = {};
    data.areas.forEach(area => {
      areaData[area.buildingId] = {};
      areaData[area.buildingId]["totalConsumption"] =
        timeType === "today"
          ? area.totalConsumption.day
          : area.totalConsumption.month;
      areaData[area.buildingId]["status"] = area.status.status;
      areaData[area.buildingId]["category"] = area.category;
    });

    let sum = Object.values(areaData).reduce(
      (a, b) => a + b.totalConsumption,
      0
    );

    let points = geoJson.features.filter(e =>
      Object.keys(areaData).includes(e.properties.buildingId)
    );

    points.forEach(point => {
      point.properties.totalConsumption =
        areaData[point.properties.buildingId].totalConsumption;
      let totalConsumptionPercent =
        (100 * areaData[point.properties.buildingId].totalConsumption) / sum;
      point.properties.totalConsumptionPercent =
        totalConsumptionPercent < 0 ? 0.0 : totalConsumptionPercent;
      point.properties.status = areaData[point.properties.buildingId].status;
      point.properties.equatorialStatus = getRawStatusFromEquatorialTableData(
        point.properties.buildingId
      );
      point.properties.totalActivePower = getTotalActivePowerFromEquatorialTableData(
        point.properties.buildingId
      );
    });

    return points.map((feature, index) => {
      let center = centerOfMass(turf.polygon(getCoordinates(feature)));
      return (
        <ConsumptionMarker
          key={index}
          feature={feature}
          center={center}
          onClick={onClick}
        ></ConsumptionMarker>
      );
    });
  };

  const renderMarkers = (style, marker, data, geoJson, onClick, timeType) => {
    if (style === "actuators") {
      return renderActuatorsPoints(data, geoJson, onClick);
    } else {
      switch (marker) {
        case "alerts":
          return renderAlertsPoints(data, geoJson);
        case "consumption":
          return renderConsumptionPoints(data, geoJson, onClick, timeType);
        default:
          return;
      }
    }
  };

  const updatePins = () => {
    let newPins = {};
    let pinsStatuses = [];

    [...filteredMarkersAllowed].forEach(position => {
      newPins[position.properties.buildingId] = isMagnituteFilterOn
        ? getStatusFromEquatorialTableData(position.properties.buildingId)
        : getPinStatusIcon(position.properties.status);

      pinsStatuses.push(position.properties.status);
    });

    setPins({ ...newPins });

    const hasLoadedStatuses = pinsStatuses.some(status => status !== undefined);

    if (hasLoadedStatuses) cancel();
  };

  const [cancel] = useTimeoutFn(updatePins, 500);

  useEffect(() => updatePins(), [
    filteredMarkersAllowed,
    equatorialTableData,
    isMagnituteFilterOn,
    allAreasData
  ]);

  useEffect(() => {
    setCurrentGeoJson({
      type: geoJson.type,
      features: geoJson.features.filter(
        feature => !feature.properties.isPrivate || loggedUser
      )
    });
  }, [geoJson]);

  useEffect(() => {
    if (!isDEMOEnv && !isLITECAMPUSEnv) return;
    setCurrentCenter(center);
    setZoomLevel(isDEMOEnv || isLITECAMPUSEnv ? 5 : 13);
    setReset(false);
  }, [reset]);

  const findLocalization = point => {
    return point.properties.buildingId === area.buildingId;
  };

  useEffect(() => {
    if (area.buildingId !== "") {
      setCurrentCenter({
        lat: filteredMarkersAllowed.find(findLocalization)?.lat,
        lng: filteredMarkersAllowed.find(findLocalization)?.lng
      });
      setZoomLevel(18);
    } else {
      setCurrentCenter(center);
      setZoomLevel(zoom);
    }
  }, [area]);

  const onMapMounted = map => {
    setMap(map);
    setMapLoadded(true);
  };

  const onMarkerClick = marker => {
    setCurrentCenter({ lat: marker.lat, lng: marker.lng });
    setZoomLevel(18);

    onPolygonClick({
      buildingId: marker.properties.buildingId,
      name: marker.properties.name
    });
  };

  const handleZoomChanged = () => {
    if (!isDEMOEnv && !isNotACampus) return;
    if (map) {
      let zoom = map.getZoom();
      let center = { lat: undefined, lng: undefined };
      setZoomLevel(zoom);

      if (zoom < 15) {
        setCurrentCenter(center);
      }
    }
  };

  const loadingElement = () => (
    <div className="loading-container">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );

  function handleClick() {
    if (legend === "Consumo") setLegend("Média móvel");
    else setLegend("Consumo");
  }

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      loadingElement={loadingElement()}
    >
      <GoogleMap
        options={{ styles: mapStyle, streetViewControl: false }}
        onLoad={onMapMounted}
        zoom={zoomLevel}
        clickableIcons={false}
        center={currentCenter}
        mapContainerStyle={{ width: "100%" }}
        onZoomChanged={handleZoomChanged}
      >
        {mapLoadded &&
          currentGeoJson &&
          style === "energy" &&
          renderSelectMarker(selectedMarker, setSelectedMarker)}
        {false && mapLoadded && <SimulationDisclaimer mapStyle={style} />}

        {mapLoadded &&
          currentGeoJson &&
          filteredMarkersAllowed &&
          window.google &&
          (isDEMOEnv || isNotACampus) &&
          filteredMarkersAllowed.map((position, index) => (
            <Marker
              key={index}
              position={{ lat: position.lat, lng: position.lng }}
              onClick={() => onMarkerClick(position)}
              icon={{
                url: isDEMOEnv
                  ? position.properties.main
                    ? pinIconInst
                    : ""
                  : pins[position.properties.buildingId],
                scaledSize: new window.google.maps.Size(35, 45)
              }}
            />
          ))}

        {mapLoadded &&
          currentGeoJson &&
          renderPolygons(
            currentGeoJson,
            onPolygonClick,
            style,
            buildings,
            allAreasData,
            selectedMarker,
            timeType
          )}
        {mapLoadded &&
          currentGeoJson &&
          (style === "energy" || style === "alerts" || style === "actuators") &&
          renderMarkers(
            style,
            selectedMarker,
            allAreasData,
            currentGeoJson,
            onPolygonClick,
            timeType
          )}
        {mapLoadded && style === "mobsense" && renderPeoplePoints(sensors)}
        {mapLoadded &&
          style === "wifi" &&
          renderWifiPoints(buildings, currentGeoJson)}
        {mapLoadded &&
          currentGeoJson &&
          selectedAlertArea.geometry &&
          renderSelectedAlertArea(selectedAlertArea, onPolygonClick)}
        {mapLoadded &&
          currentGeoJson &&
          style === "energy" &&
          selectedMarker === "consumption" &&
          legend === "Consumo" &&
          renderGradientRule(
            allAreasData ? allAreasData.areas : [],
            currentGeoJson,
            timeType
          )}

        {mapLoadded &&
          currentGeoJson &&
          style === "energy" &&
          selectedMarker === "consumption" &&
          legend === "Média móvel" &&
          !isMagnituteFilterOn &&
          renderLegend()}

        {mapLoadded &&
          currentGeoJson &&
          (style === "energy" || style === "alerts") &&
          isMagnituteFilterOn &&
          renderEquatorialLegend()}

        {mapLoadded &&
          currentGeoJson &&
          style === "energy" &&
          selectedMarker === "consumption" &&
          !isUFCGEnv &&
          !isMagnituteFilterOn && (
            <LegendSwitch onClick={handleClick} title={legend} />
          )}
      </GoogleMap>
    </LoadScript>
  );
};

Map.propTypes = {
  fullscreen: PropTypes.bool,
  center: PropTypes.object,
  zoom: PropTypes.number,
  geoJson: PropTypes.object,
  setZoomLevel: PropTypes.func,
  onPolygonClick: PropTypes.func,
  selectedAlertArea: PropTypes.object,
  marker: PropTypes.array,
  style: PropTypes.string,
  buildings: PropTypes.array,
  allAreasData: PropTypes.object,
  timeType: PropTypes.string,
  isMagnituteFilterOn: PropTypes.bool
};

export default connect(mapStateToProps)(Map);
