import axios from "axios";
import {
  getLastDemand,
  getConsumptionChartData,
  getConsumptionCompare,
  getConsumptionDataByTimeType
} from "./Consumption";
import { getAlertLogs } from "./Alerts";
import { getActuators } from "./Actuators";
import { getRNPUsers } from "./Users";
import { isNotACampus } from "../utils/EnvUtils";
import { getCAMPUS } from "./utils";

export const Service = axios.create({
  baseURL: `${process.env.REACT_APP_ENV}service/rest`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
  }
});

export const CategoryService = axios.create({
  baseURL: `${process.env.REACT_APP_ENV}service/rest`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

export const AuthService = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

const ActuatorsService = axios.create({
  baseURL: process.env.REACT_APP_ACTUATORS_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

ActuatorsService.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const isSignedIn = JSON.parse(localStorage.getItem("isSignedIn"));
    if (!isNotACampus && error.response.status === 401 && isSignedIn) {
      window.location.href = "/logout";
    }
  }
);

export { ActuatorsService };

export const AlertsService = axios.create({
  baseURL: process.env.REACT_APP_ALERTS_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

export const ConsumptionService = axios.create({
  baseURL: process.env.REACT_APP_CONSUMPTION_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

const formatAlerts = (alerts, buildingId) => {
  if (alerts.length > 0) {
    alerts.forEach(alert => {
      alert.buildingId = buildingId;
    });
  }
  return alerts;
};

export const getBuildingDashboardData = (buildingId, timeType, date) => {
  return Promise.all([
    getLastDemand(buildingId),
    getConsumptionChartData(buildingId, timeType, date),
    getConsumptionCompare(buildingId, timeType, date),
    getConsumptionDataByTimeType(buildingId, timeType, date),
    getAlertLogs(buildingId),
    getActuators(buildingId)
  ])
    .then(res => {
      return {
        demand: res[0],
        chart: res[1],
        compare: res[2],
        consumption: res[3].total,
        alerts: formatAlerts(res[4], buildingId),
        actuators: res[5],
        status: res[3].status
      };
    })
    .catch(err => {
      console.log(err);
      return {
        demand: 0,
        chart: [],
        compare: 0,
        consumption: 0,
        alerts: [],
        actuators: [],
        status: "normal"
      };
    });
};

export const getAllData = (buildingId, timeType, date) => {
  return Promise.all([
    getLastDemand(buildingId),
    getConsumptionChartData(buildingId, timeType, date),
    getConsumptionDataByTimeType(buildingId, timeType, date),
    getAlertLogs(buildingId)
  ])
    .then(res => {
      return {
        demand: res[0],
        chart: res[1],
        consumption: res[2].total,
        alerts: formatAlerts(res[3], buildingId),
        status: res[2].status
      };
    })
    .catch(err => {
      console.log(err);
      return {
        demand: 0,
        chart: [],
        compare: 0,
        consumption: 0,
        alerts: [],
        status: "normal"
      };
    });
};

export const getAllConsumptionData = (buildingId, date) => {
  return Promise.all([
    getConsumptionDataByTimeType(buildingId, "today", date),
    getConsumptionDataByTimeType(buildingId, "month", date)
  ])
    .then(res => {
      return {
        today: res[0]?.total,
        month: res[1]?.total,
        status: res[0]?.status
      };
    })
    .catch(err => {
      console.log(err);
      return {
        today: 0,
        month: 0,
        status: "normal"
      };
    });
};

export const getAllBuildingsDashoardData = async (date, authorizedGeoData) => {
  let response = {
    areas: await getZoneStatistics(date, authorizedGeoData),
    demand: 0,
    consumption: {
      day: 0,
      month: 0
    },
    alerts: [],
    chart: [],
    actuators: [],
    users: []
  };

  let calls = [];
  let chartData = {};
  chartData.day = [];
  chartData.month = [];

  await getActuators().then(actuators => (response.actuators = actuators));
  await getRNPUsers().then(users => (response.users = users));

  const filterToChartData = data => {
    return data.map(measurement => [measurement.timestamp, measurement.value]);
  };

  for (let i = 0; i < response.areas.length; i++) {
    response.areas[i].dayConsumption = filterToChartData(
      response.areas[i].dayConsumption
    );
    response.areas[i].monthConsumption = filterToChartData(
      response.areas[i].monthConsumption
    );
    response.areas[i].status.status = response.areas[
      i
    ].status.status?.toLowerCase();
    response.demand += response.areas[i].demand ? response.areas[i].demand : 0;
    response.consumption.day += response.areas[i].totalConsumption?.day;
    response.consumption.month += response.areas[i].totalConsumption?.month;
    chartData.day = chartData.day.concat(response.areas[i].dayConsumption);
    chartData.month = chartData.month.concat(
      response.areas[i].monthConsumption
    );
    chartData.day.sort(function(d1, d2) {
      return d1[0] - d2[0];
    });
    chartData.month.sort(function(d1, d2) {
      return d1[0] - d2[0];
    });

    calls.push(getAlertLogs(response.areas[i].buildingId));
  }

  let chartSumDay = {};
  chartData.day.forEach(measurement => {
    if (!chartSumDay[measurement[0]]) {
      chartSumDay[measurement[0]] = measurement[1];
    } else {
      chartSumDay[measurement[0]] += measurement[1];
    }
  });

  response.chart.day = Object.keys(chartSumDay).map(timestamp => {
    return [parseInt(timestamp), chartSumDay[timestamp]];
  });

  let chartSumMonth = {};
  chartData.month.forEach(measurement => {
    if (!chartSumMonth[measurement[0]]) {
      chartSumMonth[measurement[0]] = measurement[1];
    } else {
      chartSumMonth[measurement[0]] += measurement[1];
    }
  });

  response.chart.month = Object.keys(chartSumMonth).map(timestamp => {
    return [parseInt(timestamp), chartSumMonth[timestamp]];
  });

  return Promise.all(calls)
    .then(res => {
      for (let i = 0; i < response.areas.length; i++) {
        response.areas[i].alerts = formatAlerts(
          res[i],
          response.areas[i].buildingId
        );
        response.alerts = response.alerts.concat(response.areas[i].alerts);
      }

      return response;
    })
    .catch(() => {
      for (let i = 0; i < response.areas.length; i++) {
        response.areas[i].alerts = [];
        response.alerts = response.alerts.concat(response.areas[i].alerts);
      }

      return response;
    });
};

export const getZoneStatistics = async (date, geoData) => {
  const params = {
    referenceTime: date.getTime()
  };

  const environment = getCAMPUS();

  let response = await Service.get(`zone/${environment}/statistics`, {
    params: params
  })
    .then(res => res.data)
    .catch(() => []);

  let buildings = {};
  geoData.features.forEach(
    building =>
      (buildings[building.properties.buildingId] = building.properties.name)
  );
  for (let index = response.length - 1; index >= 0; index--) {
    if (!Object.keys(buildings).includes(response[index].buildingId)) {
      response.splice(index, 1);
    } else {
      response[index].name = buildings[response[index].buildingId];
    }
  }

  return response;
};
