import React from "react";
import Hexagon from "../Hexagon/Hexagon";
import Label from "../Label/Label";
import PropTypes from "prop-types";
import moment from "moment";
import { formatConsumption, getDemandUnit } from "../../utils/Formatter";
import "./DemandWidget.scss";

const DemandWidget = ({ demand, time, type }) => {
  const getTime = () => {
    let date = moment(time);
    return date.hour() + "h" + date.add(1, "minutes").format("mm");
  };

  const getTitle = () => {
    switch (type) {
      case "energy":
        return "Total Demand";
      case "mobsense":
        return "Nº de Pessoas - Agora";
      case "water":
        return "Water";
      default:
        return "";
    }
  };

  return (
    <div className="demand-widget-container">
      <Hexagon
        outlined
        consumption={formatConsumption(demand)}
        unity={type === "water" ? "m³" : getDemandUnit(demand)}
        type={type}
      />
      <Label title={getTitle()} type={type} subtitle={getTime()} />
    </div>
  );
};

DemandWidget.propTypes = {
  unity: PropTypes.string,
  demand: PropTypes.number,
  time: PropTypes.number,
  type: PropTypes.string
};

export default DemandWidget;
