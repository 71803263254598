import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AreaList from "../../../components/AreaList/AreaList";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import EnergyMoreInfo from "../../../assets/img/energy-more-info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowDown,
  faArrowUp,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import { EnergyDetailedFilters } from "./EnergyDetailedFilters";

import "../../Dashboard.scss";
import "./Energy.scss";

import { Service } from "../../../api/Service";
import useActiveEnergyFilterStore from "../../../stores/useActiveEnergyFilterStore";

export const getStatusIcon = status => {
  const icons = {
    above: faArrowUp,
    below: faArrowDown,
    normal: faMinus,
    invalid: faMinus,
    undefined: faMinus,
    offline: faMinus
  };

  return icons[status] || icons[undefined];
};

const EnergyDetailed = ({
  area,
  admin,
  showAll,
  timeType,
  onItemClick,
  onShowAllClick,
  onTitleClick,
  areas,
  activeCategory,
  setActiveCategory,
  allAreasConsumption,
  markers,
  setFilteredMarkers
}) => {
  let [categories, setCategories] = useState({
    "0": "Category"
  });

  const setInitialConsumptionFilters = useActiveEnergyFilterStore(
    state => state.setInitialConsumptionFilters
  );

  const activeFilter = useActiveEnergyFilterStore(state => state.activeFilter);

  const setActiveFilter = useActiveEnergyFilterStore(
    state => state.setActiveFilter
  );

  const mappedCategoryNameTranslated = {
    Categoria: "Category",
    Category: "Category",
    Educação: "Education",
    Saúde: "Health",
    Secretarias: "Secretariats",
    Praça: "Square",
    "Iluminação pública": "Public Lighting",
    Outros: "Others",
    Cultura: "Culture",
    "Categoria teste": "Test Category"
  };

  useEffect(() => {
    setInitialConsumptionFilters();

    async function fetchData() {
      // Fetch data
      const { data } = await Service.get("/building/categories");
      const results = {};
      // Store results in the results array
      data.forEach(value => {
        results[value.id] = mappedCategoryNameTranslated[value.categoryName];
      });

      // Update the options state
      setCategories({ ...categories, ...results });
    }
    // Trigger the fetch
    fetchData();
  }, []);

  const [searchArea, setSearchArea] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataFiltered, setTableDataFiltered] = useState([]);
  const [filteredTotalConsumption, setFilteredTotalConsumption] = useState({
    day: 0,
    month: 0
  });

  function handleSelectChange(value) {
    let finalValue = value === "Category" ? "" : value;
    setActiveCategory(finalValue);
  }

  useEffect(() => {
    let areas = [];

    let filteredData = [...tableData].filter(obj => {
      if (activeCategory === "" || activeCategory === "Category") return true;
      return categories[obj.category] === activeCategory;
    });

    filteredData = activeFilter.sortFunction
      ? [...filteredData].sort(activeFilter.sortFunction)
      : [...filteredData].filter(activeFilter.filterFunction);

    if (searchArea !== "") {
      filteredData = filteredData.filter(data =>
        data.name.toLowerCase().includes(searchArea.toLowerCase())
      );
    }

    filteredData.forEach(area => areas.push(area.buildingId));
    let fMarkers = [...markers].filter(obj =>
      areas.includes(obj.properties.buildingId)
    );

    setTableDataFiltered(filteredData);
    setFilteredMarkers(fMarkers);
  }, [tableData, activeFilter, searchArea, activeCategory]);

  useEffect(() => {
    let totalConsumption = { day: 0, month: 0 };
    tableDataFiltered.forEach(area => {
      totalConsumption.day += area?.totalConsumption.day;
      totalConsumption.month += area?.totalConsumption.month;
    });
    setFilteredTotalConsumption(totalConsumption);
  }, [tableDataFiltered]);

  const getStatusIconLabel = status => {
    const labels = {
      above: "Consumo atual acima da média móvel",
      below: "Consumo atual abaixo da média móvel",
      normal: "Consumo atual próximo da média móvel",
      invalid: "Medidor sem dados suficientes",
      undefined: "Medidor Offline",
      offline: "Medidor Offline"
    };

    return labels[status] || labels[undefined];
  };

  useEffect(() => {
    setTableData(areas);
  }, [categories]);

  const authToken = localStorage.getItem("AuthData")
    ? encodeURIComponent(localStorage.getItem("AuthData"))
    : encodeURIComponent(localStorage.getItem("AuthToken"));

  return (
    <>
      <div className="header">
        <div className="header-column">
          <AreaHeader
            areaName={area.name}
            areaUpperName={process.env.REACT_APP_CAMPUS}
            onUpperNameClick={onShowAllClick}
            showAll={showAll}
            detailed={true}
            onTitleClick={onTitleClick}
            typeMeter="medição"
          />
        </div>
        <div></div>
        {!showAll && admin && (
          <div className={"link-to-liteme-wrapper"}>
            <Button
              id="link-to-liteme"
              className="link-to-liteme"
              title="Para mais informações acesse o LiteMe"
              {...(admin && area.buildingId
                ? {
                    href: `${process.env.REACT_APP_ENV}/#/login/${area.buildingId}/home/${authToken}`,
                    variant: "link"
                  }
                : {})}
            >
              <img className={"more-info-btn"} src={EnergyMoreInfo} />
            </Button>
          </div>
        )}
      </div>
      <div className="energy">
        <div className="filters">
          <div className="detailed-view-filters">
            {EnergyDetailedFilters.map(filter => (
              <div
                onClick={() => setActiveFilter(filter)}
                key={filter.accessor}
                title={filter.title}
                className={`detailed-filter-wrapper ${
                  filter.accessor === activeFilter.accessor ? "active" : ""
                } ${filter.accessor}`}
                style={{ flex: filter.width }}
              >
                {filter.component}
              </div>
            ))}
            <div style={{ flex: "3", padding: "0" }}>
              <select
                className="detailed-filter-wrapper"
                style={{
                  width: "100%",
                  height: "100%",
                  whiteSpace: "break-spaces",
                  textAlign: "center"
                }}
                value={activeCategory}
                onChange={e => handleSelectChange(e.target.value)}
              >
                {Object.keys(categories).map(key => {
                  return (
                    <option key={key} value={categories[key]}>
                      {categories[key]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {activeFilter.description && (
            <div className={`description ${activeFilter.accessor}`}>
              <span className="description-title">
                {activeFilter.description ? activeFilter.title : ""}
              </span>
              <span className="description-text">
                {activeFilter.description ? activeFilter.description : ""}
              </span>
            </div>
          )}
          <div className="list-search">
            <label className="list-search-label">
              <FontAwesomeIcon icon={faSearch} className="list-search-icon" />
              <input
                className="list-search-input"
                name="search-area"
                type="text"
                value={searchArea}
                onChange={e => setSearchArea(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="table-detailed">
        <AreaList
          type="energy"
          title="Consumo por pontos de medição"
          timeType={timeType}
          onItemClick={onItemClick}
          detailed={true}
          onTitleClick={onTitleClick}
          consumptionDay={allAreasConsumption?.day}
          consumptionMonth={allAreasConsumption?.month}
          filteredTotalConsumption={filteredTotalConsumption}
        >
          {tableDataFiltered &&
            tableDataFiltered.map(area => (
              <section
                className="table-detailed-row"
                key={area.buildingId}
                name={area.name}
              >
                <div className="list-item-name-wrapper">
                  <div
                    className={`status-indicator-wrapper ed-${area?.status?.status?.toLowerCase()}`}
                  >
                    <FontAwesomeIcon
                      className="status-indicator"
                      icon={getStatusIcon(area?.status?.status?.toLowerCase())}
                      title={getStatusIconLabel(
                        area?.status?.status?.toLowerCase()
                      )}
                      size="xs"
                    />
                  </div>
                  <div className="list-item-name">{area.name}</div>
                </div>
                <div className="list-item-consumption">
                  <div className="list-item-value">
                    {area?.totalConsumption.day.toFixed(2)}
                  </div>
                </div>
                <div className="list-item-consumption">
                  <div className="list-item-value">
                    {area?.totalConsumption.month.toFixed(2)}
                  </div>
                </div>
              </section>
            ))}
        </AreaList>
      </div>
    </>
  );
};

export default EnergyDetailed;
