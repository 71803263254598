import React, { useState, useEffect } from "react";
import EquatorialAreaHeader from "./EquatorialAreaHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCheck,
  faExclamation,
  faTimes,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";
import { EquatorialEnergyDetailedFilters } from "./EquatorialEnergyDetailedFilters";

import "../../Dashboard.scss";
import "./EquatorialEnergy.scss";

import { Service } from "../../../api/Service";
import { Col, Container, Row } from "react-bootstrap";
import { EquatorialEnergyDetailedMeasurementFilters } from "./EquatorialEnergyDetailedMeasurementFilters";
import useActiveEnergyFilterStore from "../../../stores/useActiveEnergyFilterStore";
import EquatorialAreaList from "../../../components/AreaList/EquatorialAreaList";
import useSelectedMeasurementStore from "./stores/useSelectedMeasurementStore";
import useGeoJsonStore from "../../../components/Dashboard/stores/useGeoJsonStore";
import useSelectedAreaStore from "../../../components/Dashboard/stores/useSelectedAreaStore";
import useEquatorialTableDataStore from "./stores/useEquatorialTableDataStore";
import useGetDataForSelectedMeasurement from "./hooks/useGetDataForSelectedMeasurement";
import useGetMeasurementUtils from "./hooks/useGetMeasurementUtils";
import useFormatEquatorialValues from "./hooks/useFormatEquatorialValues";
import { getCAMPUS } from "../../../api/utils";

const MINUTES_TO_RELOAD = 3;

const EquatorialEnergyDetailed = ({ markers, setFilteredMarkers }) => {
  const geoJson = useGeoJsonStore(state => state.geoJson);

  const activeFilter = useActiveEnergyFilterStore(state => state.activeFilter);

  const setActiveFilter = useActiveEnergyFilterStore(
    state => state.setActiveFilter
  );

  const setInitialMagnitudeFilters = useActiveEnergyFilterStore(
    state => state.setInitialMagnitudeFilters
  );

  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );
  const setSelectedMeasurement = useSelectedMeasurementStore(
    state => state.setSelectedMeasurement
  );

  const formatMeasurementZoneData = data => {
    return data.map((item, index) => {
      const buildingId = item.buildingId;

      const building = geoJson.features.find(
        feature => feature.properties.buildingId === buildingId
      );

      return {
        ...item,
        status: item?.status?.toLowerCase(),
        online: item?.online || false,
        name: building?.properties.name || item.name || `Prédio ${index + 1}`
      };
    });
  };

  const tableData = useEquatorialTableDataStore(
    state => state.equatorialTableData
  );
  const setTableData = useEquatorialTableDataStore(
    state => state.setEquatorialTableData
  );

  const [searchArea, setSearchArea] = useState("");
  const [tableDataFiltered, setTableDataFiltered] = useState([]);

  useEffect(() => {
    setInitialMagnitudeFilters();

    async function fetchData() {
      const { data } = await Service.get(`measurement/zone/${getCAMPUS()}`);

      const formattedData = formatMeasurementZoneData(data);
      setTableData(formattedData);
    }

    fetchData();

    const intervalId = setInterval(fetchData, MINUTES_TO_RELOAD * 1000 * 60);

    return () => clearInterval(intervalId);
  }, []);

  const formattedData = useGetDataForSelectedMeasurement();

  const searchFilter = area =>
    area.name?.toLowerCase().includes(searchArea?.toLowerCase());

  const buildingIdsWithinGeoJSON = geoJson.features.map(
    feature => feature.properties.buildingId
  );

  useEffect(() => {
    const sortedData = activeFilter.sortFunction
      ? [...formattedData].sort(activeFilter.sortFunction)
      : [...formattedData];

    const filteredData = activeFilter.filterFunction
      ? [...sortedData].filter(activeFilter.filterFunction)
      : [...sortedData];

    const searchedData =
      searchArea !== ""
        ? [...filteredData].filter(searchFilter)
        : [...filteredData];

    const proccessedData = [...searchedData];
    setTableDataFiltered(
      proccessedData.filter(area =>
        buildingIdsWithinGeoJSON.includes(area.buildingId)
      )
    );

    const areas = filteredData.map(area => area.buildingId);
    const filteredMarkers = [...markers].filter(obj =>
      areas.includes(obj.properties.buildingId)
    );

    if (filteredMarkers.length < 1) return;
    setFilteredMarkers(filteredMarkers);
  }, [tableData, activeFilter, selectedMeasurement, searchArea]);

  const getStatusIconLabel = (status, online) => {
    const labels = {
      normal: "Operando Normalmente",
      "has-alerts": "Possui Alertas",
      "out-of-service": "Fora de Serviço (Falta de Energia)"
    };

    return online
      ? labels[status || "normal"]
      : "Offline (Sem enviar dados há mais de 12 horas)";
  };

  const getStatusIcon = (status, online) => {
    const icons = {
      normal: faCheck,
      "has-alerts": faExclamation,
      "out-of-service": faTimes
    };

    return online ? icons[status || "normal"] : faPowerOff;
  };

  const setSelectedArea = useSelectedAreaStore(state => state.setSelectedArea);

  const { hasNeutralValue, hasTotalValue } = useGetMeasurementUtils();

  const tableTitle = selectedMeasurement.unit
    ? `${selectedMeasurement.title} (${selectedMeasurement.unit})`
    : `${selectedMeasurement.title}`;

  const { formatValue } = useFormatEquatorialValues();

  return (
    <Container className="equatorial-energy-container">
      <Row>
        <EquatorialAreaHeader />
      </Row>
      <Container className="equatorial-detailed-filters">
        <Row>
          {EquatorialEnergyDetailedMeasurementFilters.map(filter => (
            <Col
              key={filter.accessor}
              title={filter.title}
              className={"equatorial-measurement-wrapper"}
              onClick={() => setSelectedMeasurement(filter)}
            >
              {filter.component(
                selectedMeasurement.accessor === filter.accessor
              )}
            </Col>
          ))}
        </Row>
        <Row>
          {EquatorialEnergyDetailedFilters.map(filter => (
            <Col
              onClick={() => setActiveFilter(filter)}
              key={filter.accessor}
              title={filter.title}
              className={`detailed-filter-wrapper ${
                filter.accessor === activeFilter.accessor ? "active" : ""
              } ${filter.accessor}`}
              style={{ flex: filter.width }}
            >
              {filter.component}
            </Col>
          ))}
          <Col className="list-search" xs={3}>
            <label className="list-search-label">
              <FontAwesomeIcon icon={faSearch} className="list-search-icon" />
              <input
                className="list-search-input"
                name="search-area"
                type="text"
                value={searchArea}
                onChange={e => setSearchArea(e.target.value)}
              />
            </label>
          </Col>
        </Row>
      </Container>
      <div className="table-detailed">
        <EquatorialAreaList
          type="energy"
          header={tableTitle}
          onItemClick={setSelectedArea}
        >
          {tableDataFiltered &&
            tableDataFiltered.map(area => (
              <section
                className="table-detailed-row"
                key={area.buildingId}
                name={area.name}
              >
                <div className="list-item-name-wrapper">
                  <div
                    className={`status-indicator-wrapper ${area?.status?.toLowerCase()} ${
                      area?.online ? "" : "offline"
                    }`}
                  >
                    <FontAwesomeIcon
                      className={`status-indicator ${
                        area?.online ? "" : "offline"
                      }`}
                      icon={getStatusIcon(
                        area?.status?.toLowerCase(),
                        area?.online
                      )}
                      title={getStatusIconLabel(
                        area?.status?.toLowerCase(),
                        area?.online
                      )}
                      size="xs"
                    />
                  </div>
                  <div className="list-item-name">{area.name}</div>
                </div>
                {hasNeutralValue && (
                  <div className="list-item-consumption">
                    <div className="list-item-value">
                      {formatValue(area?.value)}
                    </div>
                  </div>
                )}
                <div className="list-item-consumption">
                  <div className="list-item-value">
                    {formatValue(area?.phases?.a)}
                  </div>
                </div>
                <div className="list-item-consumption">
                  <div className="list-item-value">
                    {formatValue(area?.phases?.b)}
                  </div>
                </div>
                <div className="list-item-consumption">
                  <div className="list-item-value">
                    {formatValue(area?.phases?.c)}
                  </div>
                </div>
                {hasTotalValue && (
                  <div className="list-item-consumption">
                    <div className="list-item-value">
                      {formatValue(area?.value)}
                    </div>
                  </div>
                )}
              </section>
            ))}
        </EquatorialAreaList>
      </div>
    </Container>
  );
};

export default EquatorialEnergyDetailed;
